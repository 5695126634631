@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
 @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
 @import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
 :root {
	 --winter-w1: #eceef1;
	 --winter-w2: #013e80;
	 --winter-w3: #8a9c9b;
	 --winter-w4: #acc4d4;
	 --winter-w5: #d3e4e3;
	 --winter-link: #7ad9fe;
	 --winter-hover: rgba(158, 158, 158, 0.2);
	 --logo-l1: #f7931e;
	 --logo-l2: #0094ff;
	 --logo-l3: #8dc500;
}
 .proj-header {
	 font-family: "Roboto", sans-serif;
	 width: 100%;
	 background: var(--winter-w2);
	/* clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
	 */
	/* padding: 0 0 11%;
	 */
	 min-height: 450px;
}
 .p-header-box {
	 color: #fff;
	 width: 100%;
	 padding: 84px 0 98px;
}
 .p-header {
	 display: flex;
	 justify-content: space-between;
	 margin: 0 auto;
	 max-width: 960px;
	 padding: 62px 15px 0;
	 width: 100%;
}
 .p-header-content {
	 width: 50%;
}
 .p-header-content h1 {
	 font-size: 54px;
	 letter-spacing: -0.005em;
	 line-height: 56px;
	 font-weight: 300;
	 margin-bottom: 30px;
	 color: #fff;
}
 p.header-paragraph {
	 font-size: 20px !important;
	 text-decoration: none !important;
}
 .p-header-content p {
	 font-size: 17px;
	 font-weight: 400;
	 letter-spacing: 0.01em;
	 line-height: 1.6em;
	 margin: 16px 0;
	 color: var(--winter-w1);
	 text-decoration: underline;
}
.p-header-vector {
    margin-top: auto;
    perspective: 1000px; 
}

.p-header-vector img {
    width: 464px;
    transition: transform 0.1s ease, box-shadow 0.1s ease; 
    transform: translateZ(0);
    will-change: transform; 
    animation: float 2.5s ease-in-out infinite; 
}

@keyframes float {
    0%, 100% {
        transform: translateY(0); 
    }
    50% {
        transform: translateY(-10px);
    }
}

 .p-snow {
	 position: relative;
	/* z-index: 1000;
	 */
}
 .p-snow img {
	 position: absolute;
	 top: -91px;
	 width: 300px;
	 margin: 0 100px;
}
/* project modal */
 .p-modal-container {
	 position: fixed;
	 top: 0;
	 left: 0px;
	 right: 0px;
	 width: 100%;
	 height: 100%;
	 z-index: 10000;
	 overflow: auto;
	 background-color: rgba(0, 0, 0, 0.7);
	 justify-content: center;
	 align-items: center;
	 padding: 5%;
}
 .p-modal {
	 background-color: #fff;
	/* border-radius: 2px;
	 */
	 width: 100%;
	 max-width: 890px;
	 height: 80%;
	 position: relative;
	 overflow-y: auto;
	 overflow-x: hidden;
	 font-family: 'Roboto';
	 color: var(--winter-w2);
	 margin-top: 30px;
	 z-index: 11000;
	 border-radius: 10px;
	 box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1), 0 2px 14px rgba(0, 0, 0, 0.06);
	 transition: transform 0.3s ease, opacity 0.3s ease;
	 transform: translateY(20px);
}
 .md-card {
	 display: flex;
	 flex-direction: row;
	 min-height: 100%;
	 border-radius: 12px;
	 overflow: hidden;
	 box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
 .md-card-side {
	 flex: 1 1 100%;
	 min-height: 100%;
	 padding: 24px;
}
 .md-card-left {
	 max-width: 33.33%;
	 background: var(--winter-w2);
	 padding: 24px;
	 border-top-left-radius: 12px;
	 border-bottom-left-radius: 12px;
}
 .md-card-left .md-card-left-title {
	 margin: 20px 0 14px;
	 font-weight: 400;
	 line-height: 20px;
	 font-size: 14px;
	 letter-spacing: 0.3px;
	 color: var(--winter-w1);
	 font-family: 'Roboto';
}
 .md-card-left .md-card-left-container {
	 margin: 15px 0;
	 display: flex;
	 flex-direction: row;
	 flex-wrap: wrap;
	 list-style: none;
	 gap: 10px;
}
 .md-card-left .md-card-left-container li {
	 background: var(--winter-hover);
	 color: var(--winter-link);
	 border-radius: 4px;
	 display: inline-block;
	 font-size: 14px;
	 margin: 0 10px 10px 0;
	 padding: 4px 12px;
	 font-family: 'Roboto';
	 font-weight: 400;
	 letter-spacing: 0.3px;
	 cursor: pointer;
	 transition: background-color 0.3s ease;
}
 .md-card-left .md-card-left-container li:hover {
	 background: var(--winter-hover);
}
 .md-card-left-owner {
	 margin: 15px 0 25px;
	 display: flex;
	 flex-direction: row;
	 flex-wrap: wrap;
	 list-style: none;
	 gap: 10px;
}
 .md-card-left-owner li {
	 background: none !important;
}
 .md-card-left-owner a {
	 color: var(--logo-l1) !important;
	 margin: 0 10px 10px 0;
	 font-size: 18px;
	 transition: color 0.3s ease;
}
 .md-card-left-owner a:hover {
	 color: var(--logo-l2);
}
 .md-card-left-owner a i {
	 font-size: 20px;
}
 .md-card-left-owner a:hover i {
	 color: var(--logo-l2) !important;
}
 .md-card-left-divider {
	 margin: 20px 0;
	 display: block;
	 border-top-width: 1px;
	 border-top-style: solid;
	 border-top-color: rgba(255, 255, 255, 0.07);
}
 .md-card-right {
	 max-width: 66.66%;
	 background: rgba(255, 255, 255, 1);
	 display: flex;
	 flex-direction: column;
	 padding: 24px;
	 border-top-right-radius: 12px;
	 border-bottom-right-radius: 12px;
}
 .md-card-right-title {
	 display: flex;
	 flex-direction: row;
	 align-items: center;
	 align-content: flex-start;
	 justify-content: space-between;
	 color: rgba(0, 0, 0, 0.87);
	 font-weight: 500;
	 font-size: 24px;
	 line-height: 1.2;
	 justify-content: space-between;
}
 .md-card-right-title h2 {
	 line-height: 50px;
	 margin-bottom: 10px;
	 font-weight: 300;
	 font-size: 45px;
}
 .md-card-right-title button {
	 border: none;
	 outline: none;
	 display: flex;
	 flex-direction: row;
	 padding: 6px 9px;
	 transition: 0.3s ease;
}
 .md-card-right-title button:hover {
	 background: var(--winter-hover);
}
 .md-card-right-title button i {
	 font-size: 24px;
}
 .md-card-right-desc {
	 line-height: 25px;
	 margin-right: 15%;
	 min-height: 75px;
	 font-weight: 400;
	 letter-spacing: 0.01em;
	 margin: 16px 82px 16px 0;
	 font-size: 16px;
	 color: rgba(0, 0, 0, 0.87);
}
 .md-card-right-visit-proj {
	 display: flex;
	 flex-direction: row;
	 align-items: center;
	 align-content: center;
	 max-width: 100%;
	 justify-content: flex-end;
	 text-transform: uppercase;
}
 .md-card-right-visit-proj a {
	 color: var(--logo-l2);
	 font-weight: 500;
	 letter-spacing: 0.01em;
	 padding: 1px 12px 0;
	 line-height: 36px;
	 transition: 0.2s ease;
	 text-decoration: none;
	 font-size: 15px;
}
 .md-card-right-visit-proj a:hover {
	 background: var(--winter-hover);
}
 .md-card-right-visit-proj a i {
	 font-size: 20px;
	 vertical-align: text-bottom;
	 margin-left: 3px;
	 font-weight: 600;
}
/* project body */
 .proj-body {
	 background: var(--winter-w1);
	 display: flex;
	 flex-direction: column;
	 padding-bottom: 5px;
}
 .search-substitute {
	 position: relative;
	 width: 100%;
	 height: 91.73px;
	 background: transparent;
}
 .p-search-section {
	 position: relative;
	 width: 100%;
	 background: #042d59;
	/* background: var(--winter-w3);
	 */
	 box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.25);
	 padding: 9px 8px;
	 margin-bottom: 30px;
	 z-index: 1;
	 top: 0;
}
 .search-scrolled {
	 position: fixed;
	 top: 73px;
	 padding: 0 8px;
	 transition: top 0.5s ease;
	 box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
 .p-search {
	 margin-left: auto;
	 margin-right: auto;
	 max-width: 960px;
	 width: 100%;
	 display: flex;
	 flex-direction: row;
	 align-items: center;
	 align-content: center;
	 justify-content: space-between;
}
 .p-search-input {
	 display: flex;
	 flex-direction: row;
	 align-items: center;
	 align-content: center;
	 flex: 1;
}
 .p-search-input g {
	 font-size: 24px;
	 fill: #fff;
}
 .p-search-input input {
	 color: var(--winter-w5);
	 background: transparent;
	 text-overflow: ellipsis;
	 border: 0;
	 outline: none;
	 font-size: 16px;
	 margin-left: 8px;
	 padding: 12px 0;
	 font-weight: 500;
	 font-family: 'Roboto';
	 width: 100%;
}
 .p-search-input input::placeholder {
	 color: var(--winter-w3);
}
 .p-prev-projs button {
	 text-transform: uppercase;
	 font-family: 'Roboto';
	 color: var(--winter-w4);
	 font-weight: 400;
	 font-size: 14px;
	 letter-spacing: 0.01em;
	 background: none;
	 border: none;
	 outline: none;
	 padding: 1px 12px 0;
	 line-height: 36px;
	 min-height: 36px;
	 vertical-align: bottom;
	 transition: 0.3s ease;
	 cursor: pointer;
}
 .p-prev-projs button:hover {
	 background-color: var(--winter-hover);
	 color: var(--winter-w4);
}
 .p-prev-projs button i {
	 font-size: 19px;
	 vertical-align: text-bottom;
	 margin-left: 7px;
}
 .p-cards {
	 width: 100%;
}
 .p-grid {
	 display: grid;
	 grid-template-columns: repeat(auto-fill, minmax(385px, 1fr));
	 justify-content: center;
	 align-items: stretch;
	/* grid-gap: 60px 5%;
	 */
}
 .p-grid .p-project {
	 padding: 25px 20px;
	 background: var(--winter-w2);
	 color: var(--winter-w5);
	 transition: transform 0.3s ease, box-shadow 0.3s ease;
	 margin: 8px;
	 overflow: hidden;
	 display: flex;
	 flex-direction: column;
	 border-radius: 8px;
	 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
	 transform: translateY(0);
}
 .p-grid .p-project:hover {
	 transform: translateY(-5px);
	 box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
}
 .p-project .p-project-name {
	 font-size: 22px;
	 font-weight: 500;
	 margin-bottom: 12px;
}
 .p-project .p-project-name a {
	 color: var(--logo-l1);
	 font-family: 'Roboto';
	 transition: color 0.2s ease;
}
 .p-project:hover .p-project-name a {
	 color: var(--logo-l2);
}
 .p-project .p-project-desc {
	 font-size: 16px;
	 color: var(--winter-w4);
	 font-weight: 400;
	 font-family: 'Roboto';
	 margin-top: 10px;
	 line-height: 1.5;
}
 .p-project-labels-container {
	 display: flex;
	 flex-direction: row;
	 flex-wrap: wrap;
	 margin-top: 15px;
	 margin-bottom: 5px;
	 transform: translateX(-10px);
}
 .p-project-label {
	 padding: 4px 9px;
	 color: var(--winter-link);
	 font-weight: 400;
	 font-size: 15px;
	 font-family: 'Roboto';
	 transition: 0.2s;
	 cursor: pointer;
	 border-radius: 2px;
	 letter-spacing: 0.5px;
}
 .p-project-label:hover {
	 background: var(--winter-hover);
}
 .p-modal-link {
	 position: relative;
	 width: 100%;
	 display: flex;
	 flex-direction: row;
	 transition: 0.2s ease-in;
	 margin-top: auto;
}
 .p-modal-link button {
	 text-transform: uppercase;
	 font-family: 'Roboto';
	 color: var(--logo-l1);
	 font-weight: 500;
	 font-size: 14px;
	 letter-spacing: 0.5px;
	 background: none;
	 border: none;
	 outline: none;
	 padding: 1px 12px 0;
	 line-height: 36px;
	 min-height: 36px;
	 vertical-align: bottom;
	 transition: 0.3s ease;
	 cursor: pointer;
	 transform: translateX(-12px);
	 border-radius: 2px;
	 text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}
 .p-modal-link button:hover {
	 background-color: var(--winter-hover);
}
 .p-modal-link button i {
	 font-size: 19px;
	 vertical-align: text-bottom;
	 margin-left: 7px;
}
 .p-serial-number {
	 position: absolute;
	 bottom: 5px;
	 right: 5px;
	 background-color: rgba(255, 255, 255, 0.8);
	 padding: 2px 5px;
	 border-radius: 3px;
	 font-size: 12px;
	 font-weight: bold;
	 color: #333;
}
 .p-bottom-bar {
	 height: 35px;
	 width: 100%;
	 background: var(--winter-w1);
}
 @media only screen and (max-width: 1100px) {
	 .p-header-box {
		 padding: 0 0 70px;
	}
	 .search-scrolled {
		 position: initial;
	}
	 .search-substitute {
		 display: none;
	}
}
 @media only screen and (max-width: 748px) {
	 .p-header {
		 justify-content: center;
		 max-width: 630px;
	}
	 .p-header-content {
		 width: 100%;
	}
	 .p-header-vector {
		 display: none;
	}
}
 @media only screen and (max-width: 535px) {
	 .p-header-box {
		 padding-bottom: 20px;
	}
	 .p-header {
		 transform: scale(0.9);
		 padding-top: 20px;
	}
	.p-search {
		margin-left: auto;
		margin-right: auto;
		max-width: 960px;
		width: 100%;
		display: block;
   }
}
 