@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5715;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
html {
    scroll-behavior: smooth !important;
}

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

ul, li {
    margin: 0;
    padding: 0;
}

label {
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: #064a93;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #011830;
}

.apply-button {
    height: 44px;
    width: 312px;
}
:root {
	 --winter-w1: #eceef1;
	 --winter-w2: #081b24;
	 --winter-w3: #8a9c9b;
	 --winter-w4: #acc4d4;
	 --winter-w5: #d3e4e3;
	 --winter-link: #7ad9fe;
	 --winter-hover: rgba(158, 158, 158, 0.2);
	 --logo-l1: #f7931e;
	 --logo-l2: #0094ff;
	 --logo-l3: #8dc500;
}
 .main-nav {
	 display: flex;
	 position: fixed;
	 background: #013e80;
	 width: 100%;
	 padding: 30px 8% 0;
	 z-index: 1000;
	 transition: 0.4s;
	 top: 0;
}
 .win-resize {
	 background: #081b24;
	 background: var(--winter-w2);
	 position: relative;
}
 .nav-scrolled {
	 background: #081b24 !important;
	 background: var(--winter-w2) !important;
	 padding-top: 10px;
	 padding-bottom: 10px;
	 transition: 0.4s;
	 box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.25);
}
 .nav-logo {
	 float: left;
	 padding: 0.2rem 0;
	 transition: 0.4s;
}
 .nav-logo img {
	 width: 76px;
	 transition: 0.4s;
}
 nav {
	 width: 100%;
}
 .nav-comps {
	 width: 100%;
	 transition: 0.4s;
	 margin: 0 auto;
	 height: 100%;
	 padding: 1rem 0;
}
 .nav-comps ul {
	 list-style: none;
	 display: flex;
	 height: 100%;
	 justify-content: center;
	 transition: 0.2s;
}
 .nav-comps ul li {
	 position: relative;
	 padding: 2px 12px;
	 height: 100%;
	 display: flex;
	 align-items: center;
	 margin-left: 20px;
}
 .nav-comps ul li a {
	 color: #fff;
	 font-size: 0.7rem;
	 font-weight: 400;
	 letter-spacing: 1px;
	 text-transform: uppercase;
	 transition: 0.2s;
}
 .nav-comps ul li a:hover {
	 color: #7ad9fe;
	 color: var(--winter-link);
	 text-decoration: none;
}
 .nav-comps ul li a::before {
	 content: "";
	 position: absolute;
	 background: #6bc7b8;
	 height: 0;
	 width: 16px;
	 top: calc(100% - 0px);
	 left: 50%;
	 -webkit-transform: translateX(-50%);
	         transform: translateX(-50%);
	 transition: 0.2s;
	 z-index: -100;
}
 .comp-scrolled {
	 width: 42rem;
	 transition: 0.4s;
	 margin-right: 0;
	 padding: 0.5rem;
}
 .comp-scrolled ul li a::before {
	 height: 0;
	 left: 12px;
	 -webkit-transform: translateX(0);
	         transform: translateX(0);
}
/* .nav-comps ul li a::before {
	 content: "";
	 position: absolute;
	 background: #6bc7b8;
	 border-radius: 50%;
	 height: 3px;
	 width: 3px;
	 top: 46%;
	 left: -0.2rem;
	 transition: 0.2s;
	 z-index: -100;
}
 */
 .comp-scrolled ul li.nav-active a {
	 color: #7ad9fe;
	 color: var(--winter-link);
	 text-decoration: none;
}
 .comp-scrolled ul li.nav-active a:before {
	 border-radius: 0;
	 height: 100%;
	 width: 100%;
	 top: 0;
	 left: 0;
	 background: rgba(158, 158, 158, 0.2);
	 background: var(--winter-hover);
}
 .navbar-nav .nav-link {
	 color: white;
	 transition: 0.3s;
}
 .navbar-nav .nav-link:hover {
	 color: rgba(255, 255, 255, 0.7);
}
 .hamburger-icon {
	 color: #fff;
	 font-size: 1.8rem;
}
/* footer */
 .footer-content {
	 background: #081b24;
	 background: var(--winter-w2);
	 color: #fff;
	/* clip-path: polygon(100% 100%, 100% 6%, 0% 0%, 0% calc(100% - 0vw));
	 */
	 -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 1.5vw));
	         clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 1.5vw));
}
 .footer-top {
	 position: relative;
	 text-align: right;
	 margin-left: auto;
	 margin-right: auto;
	 max-width: 960px;
	 padding: 5px 15px;
	 width: 100%;
}
 .footer-top button {
	 color: #7ad9fe;
	 color: var(--winter-link);
	 font-weight: 400;
	 font-size: 14px;
	 letter-spacing: 0.01em;
	 background: none;
	 border: none;
	 outline: none;
	 padding: 1px 12px 0;
	 margin: 6px 8px;
	 line-height: 36px;
	 min-height: 36px;
	 vertical-align: bottom;
	 transition: 0.3s ease;
	 cursor: pointer;
}
 .footer-top button:hover {
	 background-color: rgba(158, 158, 158, 0.2);
	 background-color: var(--winter-hover);
}
 .footer-top button i {
	 font-size: 21px;
	 vertical-align: middle;
	 margin-left: 5px;
}
 .fa-heart {
	 color: red;
	 font-size: 20px;
}
 .footer-mid {
	 margin: 0 auto;
	 max-width: 960px;
	 padding: 10px 15px 90px;
	 width: 100%;
}
 .footer-mid .f-content {
	 background: #081b24;
	 background: var(--winter-w2);
	 color: #fff;
	 display: flex;
	 justify-content: space-between;
}
 .footer-mid .f-content .f-contact {
	 width: 50%;
	 flex-direction: column;
	 display: flex;
}
 .footer-mid .f-content .f-logo {
	 display: flex;
	 width: 40%;
}
 .footer-mid .f-content .f-logo img {
	 width: 100%;
	 margin-top: auto;
}
 .f-nav {
	 display: flex;
}
 .f-nav ul {
	 list-style: none;
	 margin: 0;
	 padding: 0;
	 display: block;
}
 .f-nav ul li {
	 text-decoration: none;
	 text-transform: uppercase;
	 font-weight: 400;
	 letter-spacing: 0.01em;
	 line-height: 1.6em;
	 margin-top: 15px;
}
 .f-nav ul li a {
	 font-size: 16px;
	 color: #acc4d4;
	 color: var(--winter-w4);
}
 .f-nav ul.f-nav-ul {
	 margin-left: 100px;
}
 .footer-copyright-info {
	 padding: 30px 75px 0 0;
	 margin-top: auto;
	 color: #e7e8e9;
}
 .footer-bottom {
	 background: #fff;
	 margin-left: auto;
	 margin-right: auto;
	 max-width: 960px;
	 padding: 0 15px;
	 width: 100%;
}
 .f-bottom-row {
	 max-width: 100vw;
	 padding: 20px 0 15px;
	 display: flex;
	 justify-content: space-between;
}
 .f-bottom {
	 display: flex;
	 padding-top: 5px;
}
 .f-bottom-left p {
	 font-family: 'Poppins';
	 font-size: 18px;
	 font-weight: 600;
	 margin-right: 70px;
}
 .f-bottom-right p {
	 font-size: 14px;
	/* margin-bottom: 0.5rem;
	 */
	 font-family: 'Poppins';
	 font-weight: 400;
}
 .f-media a {
	 color: #666;
	 margin-left: 30px;
	 transition: 0.3s;
}
 .f-media a:nth-of-type(1):hover {
	 color: #3b5999;
}
 .f-media a:nth-of-type(2):hover {
	 color: #55acee;
}
 .f-media a:nth-of-type(3):hover {
	 color: #e4405f;
}
 .f-media a:nth-of-type(4):hover {
	 color: #cd201f;
}
 .f-media a:nth-of-type(5):hover {
	 color: #0077b5;
}
 .f-media i {
	 font-size: 20px;
}
 
:root {
  --winter-heading: #3c4043;
  --winter-background: #f8f9fa;
  --winter-border: #e8eaed;
  --winter-w1: #eceef1;
  --winter-w2: #081b24;
  --winter-w3: #8a9c9b;
  --winter-w4: #acc4d4;
  --winter-w5: #d3e4e3;
  --winter-link: #7ad9fe;
  --winter-hover: rgba(158, 158, 158, 0.2);
  --logo-l1: #f7931e;
  --logo-l2: #0094ff;
  --logo-l3: #8dc500;
}

/* Body */
.body-content {
  color: #081b24;
  color: var(--winter-w2);
  max-width: 1080px;
  margin: 0 auto;
}

.cards-bg-sec {
  padding: 2rem 0 7%;
  background: #f8f9fa;
  background: var(--winter-background);
  border-top: 1px solid #e8eaed;
  border-top: 1px solid var(--winter-border);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 2vw));
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 2vw));
}

.card__card__title {
  color: #3c4043;
  color: var(--winter-heading);
  letter-spacing: -0.005em;
  line-height: 56px;
  font-weight: 300;
  margin-bottom: 40px;
  font-size: 42px;
}

.card__card__body {
  padding: 24px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(241, 243, 244, 0.5);
  border: 1px solid #e8eaed;
  border: 1px solid var(--winter-border);
}

.card-title {
  font-size: 18px;
  color: #3c4043;
  color: var(--winter-heading);
  font-weight: 600;
  line-height: 28px;
  font-family: "Poppins";
  padding-bottom: 20px;
  border-bottom: 1px solid #e8eaed;
  border-bottom: 1px solid var(--winter-border);
}

.card-text {
  margin: 16px 0 20px;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  color: #3c4043;
  color: var(--winter-heading);
}

/* header */
.snow {
  position: absolute;
  width: 100%;
  height: 100%;
}

#tsparticles {
  height: 100%;
}

.header {
  width: 100%;
  background: #081b24;
  background: var(--winter-w2);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 3vw));
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 3vw));
  min-height: 450px;
  overflow: hidden;
}

.main-header-content {
  justify-content: center;
  color: #fff;
  width: 100%;
  padding: 9rem 0rem 6rem;
  position: relative;
}

.main-header-content h1 {
  font-family: "Righteous", cursive;
  display: block;
  font-size: 5.5rem;
  align-self: center;
  text-align: center;
}
.header-bg {
  position: absolute;
}
.header-bg-component-1 {
  width: 45rem;
  bottom: -10rem;
  height: 29rem;
  rotate: 10deg;
  z-index: 20;
  left: 0px;
}
.header-bg-component-2 {
  width: 24rem;
  top: 0px;
  right: 0px;
}
.header-bg-component-3 {
  width: 16rem;
  top: 0px;
  left: -129px;
  -webkit-animation: spin 10s linear infinite;
          animation: spin 10s linear infinite;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.header-bg-component-4 {
  width: 20rem;
  bottom: -4rem;
  right: -9rem;
  z-index: 10;
}
.header-bg-component-5 {
  width: 20rem;
  bottom: -5rem;
  right: -7rem;
  z-index: 5;
  opacity: 50%;
}
.header-bg-component-6 {
  width: 18rem;
  bottom: -5rem;
  right: -2rem;
  z-index: 0;
  opacity: 50%;
}
.header-bg-component-7 {
  width: 40rem;
  bottom: -8rem;
  right: 20rem;
  z-index: 10;
}
.header-bg-component-8 {
  width: 40rem;
  bottom: -8.7rem;
  right: -12rem;
  height: 15rem;
  z-index: 10;
}
.header-bg-component-9 {
  width: 2.5rem;
  top: 7rem;
  right: 26rem;
  -webkit-animation: spin 10s linear infinite;
          animation: spin 10s linear infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.header-bg-component-10 {
  width: 20rem;
  top: 32.5rem;
  right: 20rem;
  opacity: 0.6;
  -webkit-animation: spin 10s linear infinite;
          animation: spin 10s linear infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (max-width: 1100px) {
  .header-bg-component-1 {
    width: 40rem;
    height: 20rem;
  }
  .header-bg-component-2 {
    width: 20rem;
    top: -5rem;
  }
  .header-bg-component-3 {
    width: 12rem;
    left: -4rem;
    top: -5rem;
  }
  .header-bg-component-4 {
    width: 14rem;
  }
  .header-bg-component-5 {
    width: 14rem;
  }
  .header-bg-component-6 {
    width: 12rem;
  }
  .header-bg-component-7 {
    width: 38rem;
    bottom: -10rem;
    right: 15rem;
  }
  .header-bg-component-8 {
    width: 35rem;
    bottom: -9rem;
    height: 10rem;
    right: -13.5rem;
  }
}
@media (max-width: 768px) {
  .header-bg-component-2 {
    width: 12rem;
    top: -2rem;
  }
  .header-bg-component-3 {
    width: 7rem;
    left: -3rem;
    top: -2rem;
  }
  .header-bg-component-4 {
    width: 10rem;
    right: -4rem;
  }
  .header-bg-component-5 {
    width: 10rem;
    right: -2rem;
  }
  .header-bg-component-6 {
    width: 8rem;
    right: 0rem;
  }
}
.header-logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.header-logo img {
  width: 260px;
  margin-top: -18px;
}

.about-swoc {
  display: flex;
  padding-bottom: 40px;
  justify-content: space-between;
}

.about-content {
  padding: 2.5rem 0;
}

.about-content h3 {
  letter-spacing: -0.005em;
  line-height: 56px;
  font-weight: 300;
  margin-bottom: 25px;
  font-size: 46px;
}

.about-content p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #3c4043;
  color: var(--winter-heading);
}

.about-vector {
  height: 100%;
  margin-top: auto;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.about-vector img {
  width: 500px;
  transition: box-shadow 0.1s ease, -webkit-transform 0.1s ease;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  transition: transform 0.1s ease, box-shadow 0.1s ease, -webkit-transform 0.1s ease;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  will-change: transform;
  -webkit-animation: float 3s ease-in-out infinite;
          animation: float 3s ease-in-out infinite;
}

@-webkit-keyframes float {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@keyframes float {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

.join-follow p {
  font-size: 1.1rem;
  line-height: 1.5;
  padding: 30px;
  text-align: center;
}

.past-numbers {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 100px 0;
  background-color: #f4f4f4;
  /* Light gray background */
}

.number-card {
  text-align: center;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, box-shadow 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
  cursor: pointer;
}

.number-card:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.number-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.countup {
  font-size: 3rem;
  color: #e74c3c;
  font-weight: bold;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .past-numbers {
    flex-direction: column;
    padding: 60px 0;
  }

  .number-card {
    margin-bottom: 20px;
  }

  .number-title {
    font-size: 1.2rem;
  }

  .countup {
    font-size: 2rem;
  }
}

.card-cover {
  margin-bottom: 20px;
}

.to-apply-card {
  max-width: 400px;
  margin: auto;
  text-decoration: none;
  overflow: hidden;
  transition: all 0.3s;
  -webkit-perspective: 1px;
          perspective: 1px;
  display: flex;
  flex-direction: column;
}

.to-apply-card:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.btn-apply {
  display: flex;
  margin-top: auto;
  margin-bottom: 10px;
}

.btn-apply iframe {
  width: 100% !important;
}

.btn-apply a {
  border: none;
  outline: none;
  border: none;
  padding: 6px 12px;
  border-radius: 2px;
  color: #fff;
  font-weight: 400;
  width: 100%;
  font-size: 18px;
  background: #3770ff;
  text-align: center;
}

.btn-apply a:hover {
  text-decoration: none;
}

.disabled {
  opacity: 0.65;
  cursor: no-drop;
  background: #3770ff !important;
}

.event-together {
  padding: 40px 0 40px;
}

.event-together h3 {
  font-size: 30px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 50px;
  text-transform: uppercase;
  word-spacing: 5px;
}

.together-logos {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin: 0 auto;
}

.together-logos span {
  display: block;
  font-family: "Poppins";
  font-size: 160px;
  line-height: 0;
  -webkit-transform: translateY(45%);
          transform: translateY(45%);
  z-index: -1;
  opacity: 0.2;
}

.logo-swoc,
.logo-cwoc {
  width: 40%;
}

.logo-swoc img {
  width: 100%;
}

.logo-cwoc img {
  width: 100%;
}

/*Timeline*/
.timeline-head {
  width: 100%;
  text-align: center;
  margin: 50px 0 0;
}

.timeline-head h3 {
  color: #081b24;
  color: var(--winter-w2);
  font-weight: 400;
  font-size: 36px;
  text-decoration: underline;
}

.timeline ul {
  padding-top: 40px;
  margin-bottom: 50px;
}

.timeline li {
  list-style-type: none;
  position: relative;
  margin: 0 auto;
  max-width: 890px;
  display: flex;
  flex-wrap: wrap;
}

.timeline li::before {
  content: " ";
  position: absolute;
  background: #081b24;
  background: var(--winter-w2);
  width: 8px;
  height: 100%;
  left: calc(50% - 4px);
  top: 14px;
}

.timeline li::after {
  content: "o";
  position: absolute;
  left: calc(50% - 14px);
  top: 0;
  background: #fff;
  border: solid 8px #081b24;
  border: solid 8px var(--winter-w2);
  border-radius: 50%;
  height: 28px;
  width: 28px;
  text-indent: -9999px;
}

.timeline ul li:last-child::before {
  height: 0;
}

.time-responsive {
  display: none;
}

time {
  display: flex;
  position: absolute;
  font-size: 20px;
  font-weight: 400;
}

.timeline ul li:nth-child(odd) time {
  right: calc(50% + 45px);
}

.timeline ul li:nth-child(even) time {
  left: calc(50% + 45px);
  margin-left: auto;
}

.timeline-item {
  position: relative;
  -webkit-transform: translateY(calc(-50% + 13px));
          transform: translateY(calc(-50% + 13px));
  padding: 24px;
  background: #081b24;
  background: var(--winter-w2);
  color: #fff;
  transition: all 300ms ease-in-out;
  cursor: default;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.timeline-item::before {
  content: "";
  position: absolute;
  top: calc(50% - 18px);
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline ul li:nth-child(odd) .timeline-item {
  right: calc(-50% - 45px);
}

.timeline ul li:nth-child(odd) .timeline-item::before {
  left: -36px;
  border-bottom: 18px solid transparent;
  border-left: 18px solid transparent;
  border-right: 18px solid #081b24;
  border-right: 18px solid var(--winter-w2);
  border-top: 18px solid transparent;
}

.timeline ul li:nth-child(even) .timeline-item {
  left: calc(-50% - 45px);
  margin-left: auto;
}

.timeline ul li:nth-child(even) .timeline-item::before {
  right: -36px;
  border-bottom: 18px solid transparent;
  border-left: 18px solid #081b24;
  border-left: 18px solid var(--winter-w2);
  border-right: 18px solid transparent;
  border-top: 18px solid transparent;
}

@media screen and (max-width: 900px) {
  .timeline ul li div {
    width: 250px;
  }

  .timeline ul li:nth-child(even) div {
    left: -289px;
  }
}

@media screen and (max-width: 600px) {
  time {
    display: none;
  }

  .time-responsive {
    display: block;
  }

  .timeline ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .timeline ul li {
    margin-left: 20px;
  }

  .timeline ul li::before,
  .timeline ul li::after {
    left: unset;
  }

  .timeline ul li div {
    width: calc(100vw - 50px);
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
  }

  .timeline ul li:nth-child(odd) .timeline-item,
  .timeline ul li:nth-child(even) .timeline-item {
    left: unset;
    right: unset;
    margin-left: unset;
  }

  .timeline ul li:nth-child(odd) .timeline-item::before,
  .timeline ul li:nth-child(even) .timeline-item::before {
    left: -36px;
    border-bottom: 18px solid transparent;
    border-left: 18px solid transparent;
    border-right: 18px solid #081b24;
    border-right: 18px solid var(--winter-w2);
    border-top: 18px solid transparent;
  }
}

.overview-section {
  padding: 40px 0 40px;
}

.overview-section h3 {
  font-size: 46px;
  font-weight: 300;
}

.overview-section p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #3c4043;
  color: var(--winter-heading);
}

.overview-container {
  display: flex;
  justify-content: space-between;
}
.overview-vector {
  width: 300px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.overview-vector img {
  width: 140%;
  margin-left: -59px;
  transition: box-shadow 0.1s ease, -webkit-transform 0.1s ease;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  transition: transform 0.1s ease, box-shadow 0.1s ease, -webkit-transform 0.1s ease;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  will-change: transform;
  -webkit-animation: float 3s ease-in-out infinite;
          animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

.overview-content {
  width: calc(100% - 380px);
}

.carousel-container {
  max-width: 1340px;
  margin: 0 auto;
  cursor: hand;
}

.prize-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 50px auto;
  padding: 40px 20px;
  max-width: 1200px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.prize-cards {
  width: calc(33.33% - 40px);
  margin: 20px;
  padding: 10px;
  background-color: transparent;
  border: none;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: box-shadow 0.3s, background-color 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s, -webkit-transform 0.3s;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.prize-cards:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.03);
}

.prize-image-wrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 50%;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.prize-image-wrapper:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.prize-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.prize-details {
  padding: 25px;
  background-color: #fff;
  border-radius: 20px;
  transition: -webkit-transform 0.3s background-color 0.3s;
  transition: transform 0.3s background-color 0.3s;
  transition: transform 0.3s background-color 0.3s, -webkit-transform 0.3s background-color 0.3s;
  position: relative;
  overflow: hidden;
}

.prize-details::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  width: 100%;
  height: 100%;
  background: inherit;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  z-index: -1;
  pointer-events: none;
}

.prize-details:hover::before {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.6)
  );
}

.prize-name {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.prize-desc {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #666;
}

@media screen and (max-width: 768px) {
  .prize-cards {
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 576px) {
  .prize-cards {
    width: 100%;
  }
}

.ehkxHv {
  z-index: 999;
  background: #f1f2f3 !important;
  cursor: default !important;
  opacity: 0;
}

.cGKbZh {
  z-index: 999;
  opacity: 0.8 !important;
  background: #f1f2f3 !important;
  box-shadow: none !important;
  color: #202124 !important;
  outline: none !important;
}

.cGKbZh:hover:enabled {
  color: #fff !important;
  background-color: #0094ff !important;
  background-color: var(--logo-l2) !important;
}

.dTGAUq {
  overflow: initial !important;
}

.xzcEF {
  display: none !important;
}

.rec-carousel-item {
  transition: opacity 0.75s;
}

.rec-carousel-item-prev,
.rec-carousel-item-next {
  opacity: 0.5;
}

.prize-card {
  display: inline-flex;
  flex-direction: column;
  margin: 0 14px;
}

.prize-card-text p {
  font-size: 16px;
}

.prize-card-img {
  padding: 0 40px 30px;
}

.prize-card-img img {
  width: 100%;
}

.sponsors-section {
  padding: 40px 0;
}

.powered-conatiner {
  margin-top: 30px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.powered-conatiner h3 {
  font-size: 36px;
  margin-bottom: 20px;
  font-weight: 300;
}

.sponsors-title {
  font-size: 36px;
  text-align: center;
  font-weight: 600;
}

.sponsors-desc{
  font-size: 18px;
  text-align: center;
  font-weight: 200;
  color: #777;
}

.sponsor-img {
  position: relative;
  width: 50%;
  /* margin: auto;
	*/
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.img-div {
  text-align: center;
  padding: 2%;
}

.speaker-section {
  padding: 50px 30px;
  text-align: center;
}

.speaker-section h1 {
  font-size: 46px;
  color: #333;
  margin-bottom: 30px;
}

.speaker-card {
  background-color: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
  transition: box-shadow 0.2s, -webkit-transform 0.2s;
  transition: transform 0.2s, box-shadow 0.2s;
  transition: transform 0.2s, box-shadow 0.2s, -webkit-transform 0.2s;
  width: 325px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.speaker-card:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}

.speaker-image-wrapper {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: -50px;
}

.speaker-img {
  width: 100%;
  height: 75%;
  object-fit: cover;
  display: block;
}

.speaker-name {
  font-size: 20px;
  margin-top: 5px;
  color: #333;
  font-weight: bold;
  text-align: center;
}

.register-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 15px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.2s;
}

.register-button:hover {
  background-color: #0056b3;
}

.speaker-social-links {
  display: flex;
  justify-content: center;
}

.speaker-social-links a {
  display: inline-block;
  margin: 0 10px;
  font-size: 1.8rem;
  color: #777;
  transition: color 0.3s;
}

.speaker-social-links a:hover {
  color: #e74c3c;
}

.event-section {
  padding: 50px 0;
  text-align: center;
}

.event-section h1 {
  font-size: 46px;
  color: #333;
  margin-bottom: 30px;
}

.event-card {
  background-color: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
  transition: box-shadow 0.2s, -webkit-transform 0.2s;
  transition: transform 0.2s, box-shadow 0.2s;
  transition: transform 0.2s, box-shadow 0.2s, -webkit-transform 0.2s;
  width: 400px;
  height: 550px;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.event-card:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}

.event-image-wrapper {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: -96px;
}

.event-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}

.event-name {
  font-size: 20px;
  margin-top: 115px;
  margin-bottom: -100px;
  color: #333;
  font-weight: bold;
  text-align: center;
  height: 30%;
}

.register-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 15px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.2s;
}

.register-button:hover {
  background-color: #0056b3;
  color: #fff;
  text-decoration: none;
}

.note-section {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.note-section p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

/* Testimonials Section */
.testimonials-section {
  background-color: #f9f9f9;
  padding: 40px 0;
  text-align: center;
}

.testimonials-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 30px !important;
  color: #333;
}

.testimonial {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  margin: 0 auto;
  max-width: 600px;
  margin-bottom: 20px;
}

.testimonial-text {
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #333;
}

.testimonial-author {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

.testimonial-role {
  font-size: 1rem;
  color: #777;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .testimonial {
    max-width: 100%;
  }
}

@media (max-width: 425px) {
  h2 {
    font-size: 1.5rem !important;
  }

  .testimonial {
    padding: 10px !important;
    margin: 7px;
  }
}

@media (max-width: 320px) {
  h2 {
    font-size: 1.3rem !important;
  }

  .testimonial {
    padding: 15px !important;
    margin: 9px !important;
  }
}

.join-follow {
  text-align: center;
  padding: 40px 0;
  background-color: #f9f9f9;
}

.join-title {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: 600;
  color: #333;
}

.social-buttons {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}

.social-buttons .btn {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  color: white;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.social-buttons .btn:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.linkedin {
  background-color: #0077b5;
}

.whatsapp {
  background-color: #25d366;
}

.twitter {
  background-color: #1da1f2;
}

@media (max-width: 768px) {
  .social-buttons {
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .social-buttons .btn {
    width: 15rem;
    justify-content: center;
  }
}

.faqs {
  padding: 80px 0;
  border-top: 1px solid #e8eaed;
  border-top: 1px solid var(--winter-border);
}

.faqs .body-content {
  max-width: 840px;
  border-bottom: 1px solid #dadce0;
}

.faq-title {
  text-align: center;
  font-size: 32px;
  margin-bottom: 48px;
  line-height: 40px;
  color: #3c4043;
  color: var(--winter-heading);
}

.faq-item {
  line-height: 1.44;
}

.question {
  position: relative;
  color: #3c4043;
  color: var(--winter-heading);
  font-size: 18px;
  line-height: 28px;
  width: 100%;
  font-family: "Poppins";
  font-weight: 400;
  cursor: pointer;
  padding: 32px 0;
  padding-right: 20px;
  border-top: 1px solid #dadce0;
  z-index: 100;
}

.answers {
  font-size: 16px;
  font-weight: 300;
  height: 0;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  transition: linear, 0.2s ease-in;
}

.question-input:checked ~ .answers {
  height: 80px;
  opacity: 1;
}

.plus {
  position: absolute;
  z-index: 5;
  font-size: 24px;
  right: 10px;
  color: #1a73e8;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  transition: 0.3s ease;
}

.question-input:checked ~ .question .plus {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.question-input {
  display: none;
}

.our-sponsors {
  padding: 3rem 7rem 5rem;
}

.community h3 {
  text-align: center;
  font-size: 2.3rem;
  text-decoration: underline;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 60px;
  height: 60px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #00838d;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.community {
  margin: auto;
}

/* Responsive */
@media only screen and (max-width: 1285px) {
  .about-swoc {
    padding: 0rem 6rem 2rem;
  }
}

@media only screen and (max-width: 1100px) {
  .main-header-content {
    padding: 6rem 7rem 10rem;
  }

  .main-header-content h1 {
    display: block;
    font-size: 4rem;
  }

  .header-logo img {
    width: 200px;
    margin-top: 0;
  }

  .about-swoc {
    display: block;
    text-align: center;
  }

  .about-vector img {
    width: 300px;
  }

  .about-content {
    padding: 1.6rem 0 0;
    max-width: 100% !important;
  }

  .about-content h3 {
    font-size: 3.2rem;
  }

  .about-content p {
    font-size: 1rem;
    padding: 0 3rem;
  }
}

@media only screen and (max-width: 890px) {
  .about-vector img {
    width: 324px;
  }

  .about-content h3 {
    font-size: 2.5rem;
  }

  .about-content p {
    font-size: 0.9rem;
    text-align: justify;
  }

  .content {
    width: 100%;
    padding: 0;
  }

  .footer-content {
    padding: 0 7%;
  }
}

@media only screen and (max-width: 768px) {
  .card__card__title {
    text-align: center;
  }

  .to-apply {
    padding: 2rem 2rem 3.5rem;
  }

  .to-apply h3 {
    font-size: 2.3rem;
  }

  .to-apply-card:hover {
    -webkit-transform: unset;
            transform: unset;
  }

  .to-apply {
    padding: 2rem 2rem 3.5rem;
  }

  .timeline-head h3 {
    font-size: 2.5rem;
  }

  .overview-vector {
    display: none;
  }

  .overview-content {
    width: 100%;
    padding: 0 20px;
  }

  .sponsor-img {
    -webkit-transform: translate(0, -50%) scale(0.7);
            transform: translate(0, -50%) scale(0.7);
  }

  .img-div {
    margin-bottom: 2%;
  }

  .f-media a {
    margin-left: 15px;
  }

  .f-media i {
    font-size: 18px;
  }

  .f-bottom-left p {
    font-size: 16px;
    margin-right: 40px;
  }

  .f-bottom-right p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 680px) {
  .main-header-content {
    padding: 5rem 6rem 9rem;
  }

  .main-header-content h1 {
    display: block;
    font-size: 3rem;
  }

  .header-logo img {
    width: 150px;
  }

  .about-swoc {
    padding: 0rem 2rem 2rem;
  }

  .about-content h3 {
    font-size: 2rem;
  }

  .about-content p {
    font-size: 0.9rem;
    padding: 0 2.5rem;
  }

  .faqs {
    padding: 2rem 2rem 2rem;
  }

  .question {
    font-size: 0.9rem;
  }

  .plus {
    font-size: 1.5em;
  }

  .question-input:checked ~ .answers {
    height: 130px;
  }
}

@media only screen and (max-width: 535px) {
  .header {
    min-height: 350px;
  }

  .main-header-content {
    padding: 4rem 6rem 7rem;
  }

  .main-header-content h1 {
    display: block;
    font-size: 2rem;
  }

  .header-logo img {
    width: 120px;
  }

  .hamburger-icon {
    color: #fff;
    /* font-size: 1.2rem;
		 */
  }

  .about-swoc {
    padding: 0rem 2rem 1rem;
  }

  .about-vector img {
    width: 324px;
  }

  .about-content h3 {
    font-size: 1.5rem;
  }

  .about-content p {
    font-size: 0.8rem;
    padding: 0 2rem;
  }

  .card-text {
    font-size: 0.9rem;
  }

  .timeline-head h3 {
    font-size: 1.9rem;
  }

  .footer-mid .f-content .f-contact {
    width: 100%;
  }

  .footer-mid .f-content .f-logo {
    display: none;
  }

  .footer-copyright-info {
    padding-top: 30px;
  }

  .f-bottom-row {
    display: block;
    text-align: center;
  }

  .f-bottom {
    display: block;
  }

  .f-bottom-left p {
    margin-right: 0;
  }

  .f-media a {
    margin: 0 7px;
  }

  .footer-bottom p {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 420px) {
  .header {
    min-height: 320px;
  }

  .main-header-content {
    padding: 4rem 4rem 5rem;
  }

  .main-header-content h1 {
    display: block;
    font-size: 2rem;
  }

  .header-logo img {
    width: 110px;
  }

  .about-vector img {
    width: 324px;
  }

  .about-content h3 {
    font-size: 1.3rem;
  }

  .about-content p {
    font-size: 0.7rem;
    padding: 0;
  }

  .to-apply h3 {
    font-size: 1.9rem;
  }

  .card-text {
    font-size: 0.85rem;
  }
}

@media only screen and (max-width: 360px) {
  .main-header-content {
    padding: 4rem 2rem 5rem;
  }

  .main-header-content h1 {
    display: block;
    font-size: 2rem;
  }

  .header-logo img {
    width: 110px;
  }

  .about-swoc {
    padding: 0rem 1rem 1rem;
  }

  .about-vector img {
    width: 324px;
  }

  .about-content h3 {
    font-size: 1.2rem;
  }

  .about-content p {
    font-size: 0.65rem;
  }
}

.sliding-container {
  overflow: hidden;
  position: relative;
}

.sliding-track {
  display: flex;
  width: calc(200%); /* Double the width for seamless looping */
  -webkit-animation: marquee-ltr 10s linear infinite;
          animation: marquee-ltr 10s linear infinite;
}

.sliding-item {
  flex: 0 0 25%; /* Ensure each item takes up 25% of the container */
  box-sizing: border-box;
}

.sponsor-img {
  width: 100%;
  height: auto;
}

@-webkit-keyframes marquee-ltr {
  0% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); /* Start from the left half */
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); /* End at the original position */
  }
}

@keyframes marquee-ltr {
  0% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); /* Start from the left half */
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); /* End at the original position */
  }
}

.title{
    font-size: 2.5rem;
    font-weight: 300;
    text-align: center;
    margin-top: 80px;
}
:root {
	 --winter-w1: #eceef1;
	 --winter-w2: #013e80;
	 --winter-w3: #8a9c9b;
	 --winter-w4: #acc4d4;
	 --winter-w5: #d3e4e3;
	 --winter-link: #7ad9fe;
	 --winter-hover: rgba(158, 158, 158, 0.2);
	 --logo-l1: #f7931e;
	 --logo-l2: #0094ff;
	 --logo-l3: #8dc500;
}
 .proj-header {
	 font-family: "Roboto", sans-serif;
	 width: 100%;
	 background: #013e80;
	 background: var(--winter-w2);
	/* clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
	 */
	/* padding: 0 0 11%;
	 */
	 min-height: 450px;
}
 .p-header-box {
	 color: #fff;
	 width: 100%;
	 padding: 84px 0 98px;
}
 .p-header {
	 display: flex;
	 justify-content: space-between;
	 margin: 0 auto;
	 max-width: 960px;
	 padding: 62px 15px 0;
	 width: 100%;
}
 .p-header-content {
	 width: 50%;
}
 .p-header-content h1 {
	 font-size: 54px;
	 letter-spacing: -0.005em;
	 line-height: 56px;
	 font-weight: 300;
	 margin-bottom: 30px;
	 color: #fff;
}
 p.header-paragraph {
	 font-size: 20px !important;
	 text-decoration: none !important;
}
 .p-header-content p {
	 font-size: 17px;
	 font-weight: 400;
	 letter-spacing: 0.01em;
	 line-height: 1.6em;
	 margin: 16px 0;
	 color: #eceef1;
	 color: var(--winter-w1);
	 text-decoration: underline;
}
.p-header-vector {
    margin-top: auto;
    -webkit-perspective: 1000px;
            perspective: 1000px; 
}

.p-header-vector img {
    width: 464px;
    transition: box-shadow 0.1s ease, -webkit-transform 0.1s ease;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    transition: transform 0.1s ease, box-shadow 0.1s ease, -webkit-transform 0.1s ease; 
    -webkit-transform: translateZ(0); 
            transform: translateZ(0);
    will-change: transform; 
    -webkit-animation: float 2.5s ease-in-out infinite; 
            animation: float 2.5s ease-in-out infinite; 
}

@-webkit-keyframes float {
    0%, 100% {
        -webkit-transform: translateY(0);
                transform: translateY(0); 
    }
    50% {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }
}

@keyframes float {
    0%, 100% {
        -webkit-transform: translateY(0);
                transform: translateY(0); 
    }
    50% {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }
}

 .p-snow {
	 position: relative;
	/* z-index: 1000;
	 */
}
 .p-snow img {
	 position: absolute;
	 top: -91px;
	 width: 300px;
	 margin: 0 100px;
}
/* project modal */
 .p-modal-container {
	 position: fixed;
	 top: 0;
	 left: 0px;
	 right: 0px;
	 width: 100%;
	 height: 100%;
	 z-index: 10000;
	 overflow: auto;
	 background-color: rgba(0, 0, 0, 0.7);
	 justify-content: center;
	 align-items: center;
	 padding: 5%;
}
 .p-modal {
	 background-color: #fff;
	/* border-radius: 2px;
	 */
	 width: 100%;
	 max-width: 890px;
	 height: 80%;
	 position: relative;
	 overflow-y: auto;
	 overflow-x: hidden;
	 font-family: 'Roboto';
	 color: #013e80;
	 color: var(--winter-w2);
	 margin-top: 30px;
	 z-index: 11000;
	 border-radius: 10px;
	 box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1), 0 2px 14px rgba(0, 0, 0, 0.06);
	 transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
	 transition: transform 0.3s ease, opacity 0.3s ease;
	 transition: transform 0.3s ease, opacity 0.3s ease, -webkit-transform 0.3s ease;
	 -webkit-transform: translateY(20px);
	         transform: translateY(20px);
}
 .md-card {
	 display: flex;
	 flex-direction: row;
	 min-height: 100%;
	 border-radius: 12px;
	 overflow: hidden;
	 box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
 .md-card-side {
	 flex: 1 1 100%;
	 min-height: 100%;
	 padding: 24px;
}
 .md-card-left {
	 max-width: 33.33%;
	 background: #013e80;
	 background: var(--winter-w2);
	 padding: 24px;
	 border-top-left-radius: 12px;
	 border-bottom-left-radius: 12px;
}
 .md-card-left .md-card-left-title {
	 margin: 20px 0 14px;
	 font-weight: 400;
	 line-height: 20px;
	 font-size: 14px;
	 letter-spacing: 0.3px;
	 color: #eceef1;
	 color: var(--winter-w1);
	 font-family: 'Roboto';
}
 .md-card-left .md-card-left-container {
	 margin: 15px 0;
	 display: flex;
	 flex-direction: row;
	 flex-wrap: wrap;
	 list-style: none;
	 grid-gap: 10px;
	 gap: 10px;
}
 .md-card-left .md-card-left-container li {
	 background: rgba(158, 158, 158, 0.2);
	 background: var(--winter-hover);
	 color: #7ad9fe;
	 color: var(--winter-link);
	 border-radius: 4px;
	 display: inline-block;
	 font-size: 14px;
	 margin: 0 10px 10px 0;
	 padding: 4px 12px;
	 font-family: 'Roboto';
	 font-weight: 400;
	 letter-spacing: 0.3px;
	 cursor: pointer;
	 transition: background-color 0.3s ease;
}
 .md-card-left .md-card-left-container li:hover {
	 background: rgba(158, 158, 158, 0.2);
	 background: var(--winter-hover);
}
 .md-card-left-owner {
	 margin: 15px 0 25px;
	 display: flex;
	 flex-direction: row;
	 flex-wrap: wrap;
	 list-style: none;
	 grid-gap: 10px;
	 gap: 10px;
}
 .md-card-left-owner li {
	 background: none !important;
}
 .md-card-left-owner a {
	 color: #f7931e !important;
	 color: var(--logo-l1) !important;
	 margin: 0 10px 10px 0;
	 font-size: 18px;
	 transition: color 0.3s ease;
}
 .md-card-left-owner a:hover {
	 color: #0094ff;
	 color: var(--logo-l2);
}
 .md-card-left-owner a i {
	 font-size: 20px;
}
 .md-card-left-owner a:hover i {
	 color: #0094ff !important;
	 color: var(--logo-l2) !important;
}
 .md-card-left-divider {
	 margin: 20px 0;
	 display: block;
	 border-top-width: 1px;
	 border-top-style: solid;
	 border-top-color: rgba(255, 255, 255, 0.07);
}
 .md-card-right {
	 max-width: 66.66%;
	 background: rgba(255, 255, 255, 1);
	 display: flex;
	 flex-direction: column;
	 padding: 24px;
	 border-top-right-radius: 12px;
	 border-bottom-right-radius: 12px;
}
 .md-card-right-title {
	 display: flex;
	 flex-direction: row;
	 align-items: center;
	 align-content: flex-start;
	 justify-content: space-between;
	 color: rgba(0, 0, 0, 0.87);
	 font-weight: 500;
	 font-size: 24px;
	 line-height: 1.2;
	 justify-content: space-between;
}
 .md-card-right-title h2 {
	 line-height: 50px;
	 margin-bottom: 10px;
	 font-weight: 300;
	 font-size: 45px;
}
 .md-card-right-title button {
	 border: none;
	 outline: none;
	 display: flex;
	 flex-direction: row;
	 padding: 6px 9px;
	 transition: 0.3s ease;
}
 .md-card-right-title button:hover {
	 background: rgba(158, 158, 158, 0.2);
	 background: var(--winter-hover);
}
 .md-card-right-title button i {
	 font-size: 24px;
}
 .md-card-right-desc {
	 line-height: 25px;
	 margin-right: 15%;
	 min-height: 75px;
	 font-weight: 400;
	 letter-spacing: 0.01em;
	 margin: 16px 82px 16px 0;
	 font-size: 16px;
	 color: rgba(0, 0, 0, 0.87);
}
 .md-card-right-visit-proj {
	 display: flex;
	 flex-direction: row;
	 align-items: center;
	 align-content: center;
	 max-width: 100%;
	 justify-content: flex-end;
	 text-transform: uppercase;
}
 .md-card-right-visit-proj a {
	 color: #0094ff;
	 color: var(--logo-l2);
	 font-weight: 500;
	 letter-spacing: 0.01em;
	 padding: 1px 12px 0;
	 line-height: 36px;
	 transition: 0.2s ease;
	 text-decoration: none;
	 font-size: 15px;
}
 .md-card-right-visit-proj a:hover {
	 background: rgba(158, 158, 158, 0.2);
	 background: var(--winter-hover);
}
 .md-card-right-visit-proj a i {
	 font-size: 20px;
	 vertical-align: text-bottom;
	 margin-left: 3px;
	 font-weight: 600;
}
/* project body */
 .proj-body {
	 background: #eceef1;
	 background: var(--winter-w1);
	 display: flex;
	 flex-direction: column;
	 padding-bottom: 5px;
}
 .search-substitute {
	 position: relative;
	 width: 100%;
	 height: 91.73px;
	 background: transparent;
}
 .p-search-section {
	 position: relative;
	 width: 100%;
	 background: #042d59;
	/* background: var(--winter-w3);
	 */
	 box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.25);
	 padding: 9px 8px;
	 margin-bottom: 30px;
	 z-index: 1;
	 top: 0;
}
 .search-scrolled {
	 position: fixed;
	 top: 73px;
	 padding: 0 8px;
	 transition: top 0.5s ease;
	 box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
 .p-search {
	 margin-left: auto;
	 margin-right: auto;
	 max-width: 960px;
	 width: 100%;
	 display: flex;
	 flex-direction: row;
	 align-items: center;
	 align-content: center;
	 justify-content: space-between;
}
 .p-search-input {
	 display: flex;
	 flex-direction: row;
	 align-items: center;
	 align-content: center;
	 flex: 1 1;
}
 .p-search-input g {
	 font-size: 24px;
	 fill: #fff;
}
 .p-search-input input {
	 color: #d3e4e3;
	 color: var(--winter-w5);
	 background: transparent;
	 text-overflow: ellipsis;
	 border: 0;
	 outline: none;
	 font-size: 16px;
	 margin-left: 8px;
	 padding: 12px 0;
	 font-weight: 500;
	 font-family: 'Roboto';
	 width: 100%;
}
 .p-search-input input::-webkit-input-placeholder {
	 color: #8a9c9b;
	 color: var(--winter-w3);
}
 .p-search-input input::placeholder {
	 color: #8a9c9b;
	 color: var(--winter-w3);
}
 .p-prev-projs button {
	 text-transform: uppercase;
	 font-family: 'Roboto';
	 color: #acc4d4;
	 color: var(--winter-w4);
	 font-weight: 400;
	 font-size: 14px;
	 letter-spacing: 0.01em;
	 background: none;
	 border: none;
	 outline: none;
	 padding: 1px 12px 0;
	 line-height: 36px;
	 min-height: 36px;
	 vertical-align: bottom;
	 transition: 0.3s ease;
	 cursor: pointer;
}
 .p-prev-projs button:hover {
	 background-color: rgba(158, 158, 158, 0.2);
	 background-color: var(--winter-hover);
	 color: #acc4d4;
	 color: var(--winter-w4);
}
 .p-prev-projs button i {
	 font-size: 19px;
	 vertical-align: text-bottom;
	 margin-left: 7px;
}
 .p-cards {
	 width: 100%;
}
 .p-grid {
	 display: grid;
	 grid-template-columns: repeat(auto-fill, minmax(385px, 1fr));
	 justify-content: center;
	 align-items: stretch;
	/* grid-gap: 60px 5%;
	 */
}
 .p-grid .p-project {
	 padding: 25px 20px;
	 background: #013e80;
	 background: var(--winter-w2);
	 color: #d3e4e3;
	 color: var(--winter-w5);
	 transition: box-shadow 0.3s ease, -webkit-transform 0.3s ease;
	 transition: transform 0.3s ease, box-shadow 0.3s ease;
	 transition: transform 0.3s ease, box-shadow 0.3s ease, -webkit-transform 0.3s ease;
	 margin: 8px;
	 overflow: hidden;
	 display: flex;
	 flex-direction: column;
	 border-radius: 8px;
	 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
	 -webkit-transform: translateY(0);
	         transform: translateY(0);
}
 .p-grid .p-project:hover {
	 -webkit-transform: translateY(-5px);
	         transform: translateY(-5px);
	 box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
}
 .p-project .p-project-name {
	 font-size: 22px;
	 font-weight: 500;
	 margin-bottom: 12px;
}
 .p-project .p-project-name a {
	 color: #f7931e;
	 color: var(--logo-l1);
	 font-family: 'Roboto';
	 transition: color 0.2s ease;
}
 .p-project:hover .p-project-name a {
	 color: #0094ff;
	 color: var(--logo-l2);
}
 .p-project .p-project-desc {
	 font-size: 16px;
	 color: #acc4d4;
	 color: var(--winter-w4);
	 font-weight: 400;
	 font-family: 'Roboto';
	 margin-top: 10px;
	 line-height: 1.5;
}
 .p-project-labels-container {
	 display: flex;
	 flex-direction: row;
	 flex-wrap: wrap;
	 margin-top: 15px;
	 margin-bottom: 5px;
	 -webkit-transform: translateX(-10px);
	         transform: translateX(-10px);
}
 .p-project-label {
	 padding: 4px 9px;
	 color: #7ad9fe;
	 color: var(--winter-link);
	 font-weight: 400;
	 font-size: 15px;
	 font-family: 'Roboto';
	 transition: 0.2s;
	 cursor: pointer;
	 border-radius: 2px;
	 letter-spacing: 0.5px;
}
 .p-project-label:hover {
	 background: rgba(158, 158, 158, 0.2);
	 background: var(--winter-hover);
}
 .p-modal-link {
	 position: relative;
	 width: 100%;
	 display: flex;
	 flex-direction: row;
	 transition: 0.2s ease-in;
	 margin-top: auto;
}
 .p-modal-link button {
	 text-transform: uppercase;
	 font-family: 'Roboto';
	 color: #f7931e;
	 color: var(--logo-l1);
	 font-weight: 500;
	 font-size: 14px;
	 letter-spacing: 0.5px;
	 background: none;
	 border: none;
	 outline: none;
	 padding: 1px 12px 0;
	 line-height: 36px;
	 min-height: 36px;
	 vertical-align: bottom;
	 transition: 0.3s ease;
	 cursor: pointer;
	 -webkit-transform: translateX(-12px);
	         transform: translateX(-12px);
	 border-radius: 2px;
	 text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}
 .p-modal-link button:hover {
	 background-color: rgba(158, 158, 158, 0.2);
	 background-color: var(--winter-hover);
}
 .p-modal-link button i {
	 font-size: 19px;
	 vertical-align: text-bottom;
	 margin-left: 7px;
}
 .p-serial-number {
	 position: absolute;
	 bottom: 5px;
	 right: 5px;
	 background-color: rgba(255, 255, 255, 0.8);
	 padding: 2px 5px;
	 border-radius: 3px;
	 font-size: 12px;
	 font-weight: bold;
	 color: #333;
}
 .p-bottom-bar {
	 height: 35px;
	 width: 100%;
	 background: #eceef1;
	 background: var(--winter-w1);
}
 @media only screen and (max-width: 1100px) {
	 .p-header-box {
		 padding: 0 0 70px;
	}
	 .search-scrolled {
		 position: initial;
	}
	 .search-substitute {
		 display: none;
	}
}
 @media only screen and (max-width: 748px) {
	 .p-header {
		 justify-content: center;
		 max-width: 630px;
	}
	 .p-header-content {
		 width: 100%;
	}
	 .p-header-vector {
		 display: none;
	}
}
 @media only screen and (max-width: 535px) {
	 .p-header-box {
		 padding-bottom: 20px;
	}
	 .p-header {
		 -webkit-transform: scale(0.9);
		         transform: scale(0.9);
		 padding-top: 20px;
	}
	.p-search {
		margin-left: auto;
		margin-right: auto;
		max-width: 960px;
		width: 100%;
		display: block;
   }
}
 
/* Reset some default styles */
 body, h1, h2, h3, p {
	 margin: 0;
	 padding: 0;
}
/* Global Styles */
 body {
	 font-family: 'Poppins';
	 background-color: #f4f4f4;
}
/* Header Styles */
 .header {
	 position: relative;
	/* Set a light background color */
	 color: #333;
	 padding: 130px 0;
}
 .header-content {
	 color: #fff;
	 display: flex;
	 justify-content: space-between;
	 align-items: center;
	 max-width: 1200px;
	 margin: 0 auto;
	 padding: 0 20px;
}
 .header-text {
	 flex: 1 1;
	 padding-right: 40px;
	/* Added some spacing between text and image */
}
 .header-title {
	 font-family: 'Poppins' !important;
	 font-size: 4rem !important;
	 padding-right: 4rem;
	 padding-bottom: 2rem;
	 margin-bottom: 20px;
}
 .header-description {
	 font-size: 1.5rem;
	 margin-bottom: 30px;
}
 .header-btn {
	 display: inline-block;
	 padding: 12px 30px;
	 font-size: 1.2rem;
	 background-color: #e74c3c;
	 color: #fff;
	 border: none;
	 border-radius: 5px;
	 cursor: pointer;
	 transition: background-color 0.3s;
}
 .header-btn:hover {
	 background-color: #c0392b;
}
 .header-image {
	 flex: 1 1;
	 display: flex;
	 justify-content: flex-end;
	 align-items: flex-end;
}
 .header-image img {
	 max-width: 100%;
	 height: auto;
	 border-radius: 10px;
	 box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
/* Responsive styles for smaller screens */
 @media (max-width: 768px) {
	 .header {
		 padding: 80px 0;
	}
	 .header-content {
		 flex-direction: column;
		 align-items: flex-start;
		 text-align: center;
	}
	 .header-text {
		 flex: none;
		 padding-right: 0;
		 margin-bottom: 30px;
	}
	 .header-title {
		 font-size: 2.5rem !important;
		 padding-right: 0;
	}
	 .header-description {
		 font-size: 1.2rem;
	}
	 .header-image {
		 margin-top: 20px;
		 align-items: center;
	}
}
/* Team Container Styles */
 .team-container {
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 flex-wrap: wrap;
	 margin: 50px auto;
	 padding: 40px 20px;
	 max-width: 1200px;
	 background-color: #fff;
	 border-radius: 20px;
	 box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
/* Team Member Styles */
 .team-member {
	 width: calc(33.33% - 40px);
	 margin: 20px;
	 padding: 10px;
	 background-color: transparent;
	 border: none;
	 border-radius: 20px;
	 box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	 text-align: center;
	 transition: box-shadow 0.3s, background-color 0.3s, -webkit-transform 0.3s;
	 transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
	 transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s, -webkit-transform 0.3s;
	 cursor: pointer;
	 position: relative;
	 overflow: hidden;
}
 .team-member:hover {
	 -webkit-transform: translateY(-5px);
	         transform: translateY(-5px);
	 box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
	 background-color: rgba(0, 0, 0, 0.03);
}
 .member-image-wrapper {
	 position: relative;
	 overflow: hidden;
	 margin-bottom: 20px;
	 width: 150px;
	 height: 150px;
	 margin: 0 auto;
	 border-radius: 50%;
	 transition: -webkit-transform 0.3s;
	 transition: transform 0.3s;
	 transition: transform 0.3s, -webkit-transform 0.3s;
}
 .member-image-wrapper:hover {
	 -webkit-transform: scale(1.05);
	         transform: scale(1.05);
}
 .member-image {
	 width: 100%;
	 height: 100%;
	 object-fit: cover;
	 object-position: center;
	 border-radius: 50%;
}
 .member-details {
	 padding: 25px;
	 background-color: #fff;
	 border-radius: 20px;
	 transition: -webkit-transform 0.3s background-color 0.3s;
	 transition: transform 0.3s background-color 0.3s;
	 transition: transform 0.3s background-color 0.3s, -webkit-transform 0.3s background-color 0.3s;
	 position: relative;
	 overflow: hidden;
}
 .member-details::before {
	 content: "";
	 position: absolute;
	 top: -10px;
	 left: -10px;
	 right: -10px;
	 bottom: -10px;
	 width: 100%;
	 height: 100%;
	 background: inherit;
	 -webkit-backdrop-filter: blur(10px);
	         backdrop-filter: blur(10px);
	 z-index: -1;
	 pointer-events: none;
}
 .member-details:hover::before {
	 background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6));
}
 .member-name {
	 font-size: 1.8rem;
	 margin-bottom: 10px;
	 color: #333;
}
 .member-role {
	 font-size: 1.2rem;
	 margin-bottom: 20px;
	 color: #666;
}
 .social-links {
	 display: flex;
	 justify-content: center;
}
 .social-links a {
	 display: inline-block;
	 margin: 0 10px;
	 font-size: 1.8rem;
	 color: #777;
	 transition: color 0.3s;
}
 .social-links a:hover {
	 color: #e74c3c;
}
/* Media Queries */
 @media screen and (max-width: 768px) {
	 .team-member {
		 width: calc(50% - 20px);
	}
}
 @media screen and (max-width: 576px) {
	 .team-member {
		 width: 100%;
	}
}
 
/* Reset some default styles */
body,
h1,
h2,
h3,
p {
    margin: 0;
    padding: 0;
}

/* Global Styles */
body {
    font-family: 'Helvetica Neue', sans-serif;
    font-family: 'Poppins';
    background-color: #f4f4f4;
}

/* Header Styles */
.header {
    position: relative;
    /* Set a light background color */
    color: #333;
    padding: 130px 0;
}

.sponsor-header-content {
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.header-text {
    flex: 1 1;
    padding-right: 40px;
    /* Added some spacing between text and image */
}

.sponsor-header-title {
    font-family: 'Poppins' !important;
    font-size: 4rem !important;
    padding-right: 8rem;
    padding-bottom: 2rem;
    margin-bottom: 20px;
}

.sponsor-header-description {
    font-size: 1.4rem;
    margin-bottom: 30px;
}

.header-btn {
    display: inline-block;
    padding: 12px 30px;
    font-size: 1.2rem;
    background-color: #e74c3c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.header-image {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.header-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .header {
        padding: 80px 0;
    }

    .sponsor-header-content {
        flex-direction: column;
        align-items: flex-start;
        text-align: center;

    }

    .header-text {
        flex: none;
        padding-right: 0;
        margin-bottom: 30px;
    }

    .sponsor-header-title {
        font-size: 2.5rem !important;
        padding-right: 0;
    }

    .sponsor-header-description {
        font-size: 1.2rem;
    }

    .header-image {
        margin-top: 20px;
        align-items: center;
    }
    .sponsor-container {
        justify-content: center !important;
    }
    
    .sponsor-item {
        width: calc(50% - 5px) !important;
    }
}

.section {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 30px;
    background-color: rgb(247, 247, 247);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

h3 {
    font-family: 'Poppins' !important;
    margin-left: 10px;
    margin-bottom: 12px;
    font-size: 26px;
    color: #333;
}

.sponsor-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.sponsor-item {
    width: calc(25% - 20px);
    margin-bottom: 20px;
    padding: 10px;
    box-sizing: border-box;
    background-color: rgb(247, 247, 247);
    border-radius: 5px;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.sponsor-item:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.sponsor-img {
    max-width: 100% !important;
    height: auto;
    display: block;
    margin: 0 auto;
    border-radius: 5px;
}


/* footer.css */
footer {
    background-color: #013e80;
    color: #fff;
    padding: 0;
  }
  
  .footer-top {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .footer-mid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    background-color: #013e80;
  }
  
  .f-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #013e80;
  }
  
  .f-contact {
    flex: 1 1;
  }
  
  .f-nav ul {
    list-style: none;
    padding: 0;
  }
  
  .f-nav ul li {
    margin-bottom: 10px;
  }
  
  .f-nav ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .f-logo img {
    max-width: 384px;
  }
  
  .footer-bottom {
    background-color: #013e80;
    padding: 10px 0;
    text-align: center;
  }
  
  .f-bottom-left,
  .f-bottom-right {
    display: inline-block;
    background-color: #013e80;
  }
  
  .f-bottom-right a {
    color: #f39c12;
    text-decoration: none;
    background-color: #013e80;
  }
  
  .f-media a {
    color: #fff;
    margin-right: 15px;
    font-size: 18px;
  }
  
  .f-media a:hover {
    color: #f39c12;
  }
  

  
.snowflake {
    position: absolute;
    top: -10px;
    pointer-events: none;
    font-size: 24px;
    color: #00f; 
    -webkit-user-select: none; 
            user-select: none;
    z-index: 1000;
    opacity: 0.8;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    font-family: Arial, sans-serif; 
  }
  
  @-webkit-keyframes snowflake-fall {
    0% {
      -webkit-transform: translateY(-100vh) rotate(0deg);
              transform: translateY(-100vh) rotate(0deg);
    }
    100% {
      -webkit-transform: translateY(100vh) rotate(360deg);
              transform: translateY(100vh) rotate(360deg);
    }
  }
  
  @keyframes snowflake-fall {
    0% {
      -webkit-transform: translateY(-100vh) rotate(0deg);
              transform: translateY(-100vh) rotate(0deg);
    }
    100% {
      -webkit-transform: translateY(100vh) rotate(360deg);
              transform: translateY(100vh) rotate(360deg);
    }
  }
  
  .snowflake {
    -webkit-animation: snowflake-fall linear infinite;
            animation: snowflake-fall linear infinite;
  }
  
.snowfall {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none; /* So that it doesn't interfere with other UI elements */
    overflow: hidden;
    z-index: 9999; 
  }
  
  .snowflake {
    position: absolute;
    top: -10%; /* Start above the viewport */
    color: #ffffff;
    -webkit-user-select: none;
            user-select: none;
    pointer-events: none;
    -webkit-animation: fall linear infinite;
            animation: fall linear infinite;
  }
  
  @-webkit-keyframes fall {
    to {
      -webkit-transform: translateY(100vh);
              transform: translateY(100vh); /* Fall to the bottom of the viewport */
    }
  }
  
  @keyframes fall {
    to {
      -webkit-transform: translateY(100vh);
              transform: translateY(100vh); /* Fall to the bottom of the viewport */
    }
  }
  
.progress-bar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 6.6px;  /* height */
    background-color: rgba(255, 255, 255, 0.2); 
    z-index: 9999;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #03aeed; 
    transition: width 0.2s ease-out;
  }

