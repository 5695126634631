.snowfall {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none; /* So that it doesn't interfere with other UI elements */
    overflow: hidden;
    z-index: 9999; 
  }
  
  .snowflake {
    position: absolute;
    top: -10%; /* Start above the viewport */
    color: #ffffff;
    user-select: none;
    pointer-events: none;
    animation: fall linear infinite;
  }
  
  @keyframes fall {
    to {
      transform: translateY(100vh); /* Fall to the bottom of the viewport */
    }
  }
  