@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

/* Reset some default styles */
body,
h1,
h2,
h3,
p {
    margin: 0;
    padding: 0;
}

/* Global Styles */
body {
    font-family: 'Helvetica Neue', sans-serif;
    font-family: 'Poppins';
    background-color: #f4f4f4;
}

/* Header Styles */
.header {
    position: relative;
    /* Set a light background color */
    color: #333;
    padding: 130px 0;
}

.sponsor-header-content {
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.header-text {
    flex: 1;
    padding-right: 40px;
    /* Added some spacing between text and image */
}

.sponsor-header-title {
    font-family: 'Poppins' !important;
    font-size: 4rem !important;
    padding-right: 8rem;
    padding-bottom: 2rem;
    margin-bottom: 20px;
}

.sponsor-header-description {
    font-size: 1.4rem;
    margin-bottom: 30px;
}

.header-btn {
    display: inline-block;
    padding: 12px 30px;
    font-size: 1.2rem;
    background-color: #e74c3c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.header-image {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.header-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .header {
        padding: 80px 0;
    }

    .sponsor-header-content {
        flex-direction: column;
        align-items: flex-start;
        text-align: center;

    }

    .header-text {
        flex: none;
        padding-right: 0;
        margin-bottom: 30px;
    }

    .sponsor-header-title {
        font-size: 2.5rem !important;
        padding-right: 0;
    }

    .sponsor-header-description {
        font-size: 1.2rem;
    }

    .header-image {
        margin-top: 20px;
        align-items: center;
    }
    .sponsor-container {
        justify-content: center !important;
    }
    
    .sponsor-item {
        width: calc(50% - 5px) !important;
    }
}

.section {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 30px;
    background-color: rgb(247, 247, 247);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

h3 {
    font-family: 'Poppins' !important;
    margin-left: 10px;
    margin-bottom: 12px;
    font-size: 26px;
    color: #333;
}

.sponsor-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.sponsor-item {
    width: calc(25% - 20px);
    margin-bottom: 20px;
    padding: 10px;
    box-sizing: border-box;
    background-color: rgb(247, 247, 247);
    border-radius: 5px;
    transition: transform 0.2s;
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.sponsor-item:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.sponsor-img {
    max-width: 100% !important;
    height: auto;
    display: block;
    margin: 0 auto;
    border-radius: 5px;
}

