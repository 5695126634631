@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

:root {
  --winter-heading: #3c4043;
  --winter-background: #f8f9fa;
  --winter-border: #e8eaed;
  --winter-w1: #eceef1;
  --winter-w2: #081b24;
  --winter-w3: #8a9c9b;
  --winter-w4: #acc4d4;
  --winter-w5: #d3e4e3;
  --winter-link: #7ad9fe;
  --winter-hover: rgba(158, 158, 158, 0.2);
  --logo-l1: #f7931e;
  --logo-l2: #0094ff;
  --logo-l3: #8dc500;
}

/* Body */
.body-content {
  color: var(--winter-w2);
  max-width: 1080px;
  margin: 0 auto;
}

.cards-bg-sec {
  padding: 2rem 0 7%;
  background: var(--winter-background);
  border-top: 1px solid var(--winter-border);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 2vw));
}

.card__card__title {
  color: var(--winter-heading);
  letter-spacing: -0.005em;
  line-height: 56px;
  font-weight: 300;
  margin-bottom: 40px;
  font-size: 42px;
}

.card__card__body {
  padding: 24px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 8px 0 rgba(241, 243, 244, 0.5);
  border: 1px solid var(--winter-border);
}

.card-title {
  font-size: 18px;
  color: var(--winter-heading);
  font-weight: 600;
  line-height: 28px;
  font-family: "Poppins";
  padding-bottom: 20px;
  border-bottom: 1px solid var(--winter-border);
}

.card-text {
  margin: 16px 0 20px;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  color: var(--winter-heading);
}

/* header */
.snow {
  position: absolute;
  width: 100%;
  height: 100%;
}

#tsparticles {
  height: 100%;
}

.header {
  width: 100%;
  background: var(--winter-w2);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 3vw));
  min-height: 450px;
  overflow: hidden;
}

.main-header-content {
  justify-content: center;
  color: #fff;
  width: 100%;
  padding: 9rem 0rem 6rem;
  position: relative;
}

.main-header-content h1 {
  font-family: "Righteous", cursive;
  display: block;
  font-size: 5.5rem;
  align-self: center;
  text-align: center;
}
.header-bg {
  position: absolute;
}
.header-bg-component-1 {
  width: 45rem;
  bottom: -10rem;
  height: 29rem;
  rotate: 10deg;
  z-index: 20;
  left: 0px;
}
.header-bg-component-2 {
  width: 24rem;
  top: 0px;
  right: 0px;
}
.header-bg-component-3 {
  width: 16rem;
  top: 0px;
  left: -129px;
  animation: spin 10s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.header-bg-component-4 {
  width: 20rem;
  bottom: -4rem;
  right: -9rem;
  z-index: 10;
}
.header-bg-component-5 {
  width: 20rem;
  bottom: -5rem;
  right: -7rem;
  z-index: 5;
  opacity: 50%;
}
.header-bg-component-6 {
  width: 18rem;
  bottom: -5rem;
  right: -2rem;
  z-index: 0;
  opacity: 50%;
}
.header-bg-component-7 {
  width: 40rem;
  bottom: -8rem;
  right: 20rem;
  z-index: 10;
}
.header-bg-component-8 {
  width: 40rem;
  bottom: -8.7rem;
  right: -12rem;
  height: 15rem;
  z-index: 10;
}
.header-bg-component-9 {
  width: 2.5rem;
  top: 7rem;
  right: 26rem;
  animation: spin 10s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-bg-component-10 {
  width: 20rem;
  top: 32.5rem;
  right: 20rem;
  opacity: 0.6;
  animation: spin 10s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1100px) {
  .header-bg-component-1 {
    width: 40rem;
    height: 20rem;
  }
  .header-bg-component-2 {
    width: 20rem;
    top: -5rem;
  }
  .header-bg-component-3 {
    width: 12rem;
    left: -4rem;
    top: -5rem;
  }
  .header-bg-component-4 {
    width: 14rem;
  }
  .header-bg-component-5 {
    width: 14rem;
  }
  .header-bg-component-6 {
    width: 12rem;
  }
  .header-bg-component-7 {
    width: 38rem;
    bottom: -10rem;
    right: 15rem;
  }
  .header-bg-component-8 {
    width: 35rem;
    bottom: -9rem;
    height: 10rem;
    right: -13.5rem;
  }
}
@media (max-width: 768px) {
  .header-bg-component-2 {
    width: 12rem;
    top: -2rem;
  }
  .header-bg-component-3 {
    width: 7rem;
    left: -3rem;
    top: -2rem;
  }
  .header-bg-component-4 {
    width: 10rem;
    right: -4rem;
  }
  .header-bg-component-5 {
    width: 10rem;
    right: -2rem;
  }
  .header-bg-component-6 {
    width: 8rem;
    right: 0rem;
  }
}
.header-logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.header-logo img {
  width: 260px;
  margin-top: -18px;
}

.about-swoc {
  display: flex;
  padding-bottom: 40px;
  justify-content: space-between;
}

.about-content {
  padding: 2.5rem 0;
}

.about-content h3 {
  letter-spacing: -0.005em;
  line-height: 56px;
  font-weight: 300;
  margin-bottom: 25px;
  font-size: 46px;
}

.about-content p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: var(--winter-heading);
}

.about-vector {
  height: 100%;
  margin-top: auto;
  perspective: 1000px;
}

.about-vector img {
  width: 500px;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  transform: translateZ(0);
  will-change: transform;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.join-follow p {
  font-size: 1.1rem;
  line-height: 1.5;
  padding: 30px;
  text-align: center;
}

.past-numbers {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 100px 0;
  background-color: #f4f4f4;
  /* Light gray background */
}

.number-card {
  text-align: center;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.number-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.number-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.countup {
  font-size: 3rem;
  color: #e74c3c;
  font-weight: bold;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .past-numbers {
    flex-direction: column;
    padding: 60px 0;
  }

  .number-card {
    margin-bottom: 20px;
  }

  .number-title {
    font-size: 1.2rem;
  }

  .countup {
    font-size: 2rem;
  }
}

.card-cover {
  margin-bottom: 20px;
}

.to-apply-card {
  max-width: 400px;
  margin: auto;
  text-decoration: none;
  overflow: hidden;
  transition: all 0.3s;
  perspective: 1px;
  display: flex;
  flex-direction: column;
}

.to-apply-card:hover {
  transform: scale(1.02);
}

.btn-apply {
  display: flex;
  margin-top: auto;
  margin-bottom: 10px;
}

.btn-apply iframe {
  width: 100% !important;
}

.btn-apply a {
  border: none;
  outline: none;
  border: none;
  padding: 6px 12px;
  border-radius: 2px;
  color: #fff;
  font-weight: 400;
  width: 100%;
  font-size: 18px;
  background: #3770ff;
  text-align: center;
}

.btn-apply a:hover {
  text-decoration: none;
}

.disabled {
  opacity: 0.65;
  cursor: no-drop;
  background: #3770ff !important;
}

.event-together {
  padding: 40px 0 40px;
}

.event-together h3 {
  font-size: 30px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 50px;
  text-transform: uppercase;
  word-spacing: 5px;
}

.together-logos {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin: 0 auto;
}

.together-logos span {
  display: block;
  font-family: "Poppins";
  font-size: 160px;
  line-height: 0;
  transform: translateY(45%);
  z-index: -1;
  opacity: 0.2;
}

.logo-swoc,
.logo-cwoc {
  width: 40%;
}

.logo-swoc img {
  width: 100%;
}

.logo-cwoc img {
  width: 100%;
}

/*Timeline*/
.timeline-head {
  width: 100%;
  text-align: center;
  margin: 50px 0 0;
}

.timeline-head h3 {
  color: var(--winter-w2);
  font-weight: 400;
  font-size: 36px;
  text-decoration: underline;
}

.timeline ul {
  padding-top: 40px;
  margin-bottom: 50px;
}

.timeline li {
  list-style-type: none;
  position: relative;
  margin: 0 auto;
  max-width: 890px;
  display: flex;
  flex-wrap: wrap;
}

.timeline li::before {
  content: " ";
  position: absolute;
  background: var(--winter-w2);
  width: 8px;
  height: 100%;
  left: calc(50% - 4px);
  top: 14px;
}

.timeline li::after {
  content: "o";
  position: absolute;
  left: calc(50% - 14px);
  top: 0;
  background: #fff;
  border: solid 8px var(--winter-w2);
  border-radius: 50%;
  height: 28px;
  width: 28px;
  text-indent: -9999px;
}

.timeline ul li:last-child::before {
  height: 0;
}

.time-responsive {
  display: none;
}

time {
  display: flex;
  position: absolute;
  font-size: 20px;
  font-weight: 400;
}

.timeline ul li:nth-child(odd) time {
  right: calc(50% + 45px);
}

.timeline ul li:nth-child(even) time {
  left: calc(50% + 45px);
  margin-left: auto;
}

.timeline-item {
  position: relative;
  transform: translateY(calc(-50% + 13px));
  padding: 24px;
  background: var(--winter-w2);
  color: #fff;
  transition: all 300ms ease-in-out;
  cursor: default;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.timeline-item::before {
  content: "";
  position: absolute;
  top: calc(50% - 18px);
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline ul li:nth-child(odd) .timeline-item {
  right: calc(-50% - 45px);
}

.timeline ul li:nth-child(odd) .timeline-item::before {
  left: -36px;
  border-bottom: 18px solid transparent;
  border-left: 18px solid transparent;
  border-right: 18px solid var(--winter-w2);
  border-top: 18px solid transparent;
}

.timeline ul li:nth-child(even) .timeline-item {
  left: calc(-50% - 45px);
  margin-left: auto;
}

.timeline ul li:nth-child(even) .timeline-item::before {
  right: -36px;
  border-bottom: 18px solid transparent;
  border-left: 18px solid var(--winter-w2);
  border-right: 18px solid transparent;
  border-top: 18px solid transparent;
}

@media screen and (max-width: 900px) {
  .timeline ul li div {
    width: 250px;
  }

  .timeline ul li:nth-child(even) div {
    left: -289px;
  }
}

@media screen and (max-width: 600px) {
  time {
    display: none;
  }

  .time-responsive {
    display: block;
  }

  .timeline ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .timeline ul li {
    margin-left: 20px;
  }

  .timeline ul li::before,
  .timeline ul li::after {
    left: unset;
  }

  .timeline ul li div {
    width: calc(100vw - 50px);
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
  }

  .timeline ul li:nth-child(odd) .timeline-item,
  .timeline ul li:nth-child(even) .timeline-item {
    left: unset;
    right: unset;
    margin-left: unset;
  }

  .timeline ul li:nth-child(odd) .timeline-item::before,
  .timeline ul li:nth-child(even) .timeline-item::before {
    left: -36px;
    border-bottom: 18px solid transparent;
    border-left: 18px solid transparent;
    border-right: 18px solid var(--winter-w2);
    border-top: 18px solid transparent;
  }
}

.overview-section {
  padding: 40px 0 40px;
}

.overview-section h3 {
  font-size: 46px;
  font-weight: 300;
}

.overview-section p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: var(--winter-heading);
}

.overview-container {
  display: flex;
  justify-content: space-between;
}
.overview-vector {
  width: 300px;
  perspective: 1000px;
}

.overview-vector img {
  width: 140%;
  margin-left: -59px;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  transform: translateZ(0);
  will-change: transform;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.overview-content {
  width: calc(100% - 380px);
}

.carousel-container {
  max-width: 1340px;
  margin: 0 auto;
  cursor: hand;
}

.prize-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 50px auto;
  padding: 40px 20px;
  max-width: 1200px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.prize-cards {
  width: calc(33.33% - 40px);
  margin: 20px;
  padding: 10px;
  background-color: transparent;
  border: none;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.prize-cards:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.03);
}

.prize-image-wrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 50%;
  transition: transform 0.3s;
}

.prize-image-wrapper:hover {
  transform: scale(1.05);
}

.prize-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.prize-details {
  padding: 25px;
  background-color: #fff;
  border-radius: 20px;
  transition: transform 0.3s background-color 0.3s;
  position: relative;
  overflow: hidden;
}

.prize-details::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  width: 100%;
  height: 100%;
  background: inherit;
  backdrop-filter: blur(10px);
  z-index: -1;
  pointer-events: none;
}

.prize-details:hover::before {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.6)
  );
}

.prize-name {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.prize-desc {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #666;
}

@media screen and (max-width: 768px) {
  .prize-cards {
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 576px) {
  .prize-cards {
    width: 100%;
  }
}

.ehkxHv {
  z-index: 999;
  background: #f1f2f3 !important;
  cursor: default !important;
  opacity: 0;
}

.cGKbZh {
  z-index: 999;
  opacity: 0.8 !important;
  background: #f1f2f3 !important;
  box-shadow: none !important;
  color: #202124 !important;
  outline: none !important;
}

.cGKbZh:hover:enabled {
  color: #fff !important;
  background-color: var(--logo-l2) !important;
}

.dTGAUq {
  overflow: initial !important;
}

.xzcEF {
  display: none !important;
}

.rec-carousel-item {
  transition: opacity 0.75s;
}

.rec-carousel-item-prev,
.rec-carousel-item-next {
  opacity: 0.5;
}

.prize-card {
  display: inline-flex;
  flex-direction: column;
  margin: 0 14px;
}

.prize-card-text p {
  font-size: 16px;
}

.prize-card-img {
  padding: 0 40px 30px;
}

.prize-card-img img {
  width: 100%;
}

.sponsors-section {
  padding: 40px 0;
}

.powered-conatiner {
  margin-top: 30px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.powered-conatiner h3 {
  font-size: 36px;
  margin-bottom: 20px;
  font-weight: 300;
}

.sponsors-title {
  font-size: 36px;
  text-align: center;
  font-weight: 600;
}

.sponsors-desc{
  font-size: 18px;
  text-align: center;
  font-weight: 200;
  color: #777;
}

.sponsor-img {
  position: relative;
  width: 50%;
  /* margin: auto;
	*/
  top: 50%;
  transform: translate(0, -50%);
}

.img-div {
  text-align: center;
  padding: 2%;
}

.speaker-section {
  padding: 50px 30px;
  text-align: center;
}

.speaker-section h1 {
  font-size: 46px;
  color: #333;
  margin-bottom: 30px;
}

.speaker-card {
  background-color: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
  transition: transform 0.2s, box-shadow 0.2s;
  width: 325px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.speaker-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}

.speaker-image-wrapper {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: -50px;
}

.speaker-img {
  width: 100%;
  height: 75%;
  object-fit: cover;
  display: block;
}

.speaker-name {
  font-size: 20px;
  margin-top: 5px;
  color: #333;
  font-weight: bold;
  text-align: center;
}

.register-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 15px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.2s;
}

.register-button:hover {
  background-color: #0056b3;
}

.speaker-social-links {
  display: flex;
  justify-content: center;
}

.speaker-social-links a {
  display: inline-block;
  margin: 0 10px;
  font-size: 1.8rem;
  color: #777;
  transition: color 0.3s;
}

.speaker-social-links a:hover {
  color: #e74c3c;
}

.event-section {
  padding: 50px 0;
  text-align: center;
}

.event-section h1 {
  font-size: 46px;
  color: #333;
  margin-bottom: 30px;
}

.event-card {
  background-color: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
  transition: transform 0.2s, box-shadow 0.2s;
  width: 400px;
  height: 550px;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}

.event-image-wrapper {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: -96px;
}

.event-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}

.event-name {
  font-size: 20px;
  margin-top: 115px;
  margin-bottom: -100px;
  color: #333;
  font-weight: bold;
  text-align: center;
  height: 30%;
}

.register-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 15px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.2s;
}

.register-button:hover {
  background-color: #0056b3;
  color: #fff;
  text-decoration: none;
}

.note-section {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.note-section p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

/* Testimonials Section */
.testimonials-section {
  background-color: #f9f9f9;
  padding: 40px 0;
  text-align: center;
}

.testimonials-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 30px !important;
  color: #333;
}

.testimonial {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s;
  margin: 0 auto;
  max-width: 600px;
  margin-bottom: 20px;
}

.testimonial-text {
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #333;
}

.testimonial-author {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

.testimonial-role {
  font-size: 1rem;
  color: #777;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .testimonial {
    max-width: 100%;
  }
}

@media (max-width: 425px) {
  h2 {
    font-size: 1.5rem !important;
  }

  .testimonial {
    padding: 10px !important;
    margin: 7px;
  }
}

@media (max-width: 320px) {
  h2 {
    font-size: 1.3rem !important;
  }

  .testimonial {
    padding: 15px !important;
    margin: 9px !important;
  }
}

.join-follow {
  text-align: center;
  padding: 40px 0;
  background-color: #f9f9f9;
}

.join-title {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: 600;
  color: #333;
}

.social-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-buttons .btn {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  color: white;
  transition: transform 0.3s;
}

.social-buttons .btn:hover {
  transform: translateY(-3px);
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.linkedin {
  background-color: #0077b5;
}

.whatsapp {
  background-color: #25d366;
}

.twitter {
  background-color: #1da1f2;
}

@media (max-width: 768px) {
  .social-buttons {
    flex-direction: column;
    gap: 15px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .social-buttons .btn {
    width: 15rem;
    justify-content: center;
  }
}

.faqs {
  padding: 80px 0;
  border-top: 1px solid var(--winter-border);
}

.faqs .body-content {
  max-width: 840px;
  border-bottom: 1px solid #dadce0;
}

.faq-title {
  text-align: center;
  font-size: 32px;
  margin-bottom: 48px;
  line-height: 40px;
  color: var(--winter-heading);
}

.faq-item {
  line-height: 1.44;
}

.question {
  position: relative;
  color: var(--winter-heading);
  font-size: 18px;
  line-height: 28px;
  width: 100%;
  font-family: "Poppins";
  font-weight: 400;
  cursor: pointer;
  padding: 32px 0;
  padding-right: 20px;
  border-top: 1px solid #dadce0;
  z-index: 100;
}

.answers {
  font-size: 16px;
  font-weight: 300;
  height: 0;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  -webkit-transition: linear, 0.2s ease-in;
  -moz-transition: linear, 0.2s ease-in;
  -o-transition: linear, 0.2s ease-in;
  transition: linear, 0.2s ease-in;
}

.question-input:checked ~ .answers {
  height: 80px;
  opacity: 1;
}

.plus {
  position: absolute;
  z-index: 5;
  font-size: 24px;
  right: 10px;
  color: #1a73e8;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.question-input:checked ~ .question .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.question-input {
  display: none;
}

.our-sponsors {
  padding: 3rem 7rem 5rem;
}

.community h3 {
  text-align: center;
  font-size: 2.3rem;
  text-decoration: underline;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 60px;
  height: 60px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #00838d;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.community {
  margin: auto;
}

/* Responsive */
@media only screen and (max-width: 1285px) {
  .about-swoc {
    padding: 0rem 6rem 2rem;
  }
}

@media only screen and (max-width: 1100px) {
  .main-header-content {
    padding: 6rem 7rem 10rem;
  }

  .main-header-content h1 {
    display: block;
    font-size: 4rem;
  }

  .header-logo img {
    width: 200px;
    margin-top: 0;
  }

  .about-swoc {
    display: block;
    text-align: center;
  }

  .about-vector img {
    width: 300px;
  }

  .about-content {
    padding: 1.6rem 0 0;
    max-width: 100% !important;
  }

  .about-content h3 {
    font-size: 3.2rem;
  }

  .about-content p {
    font-size: 1rem;
    padding: 0 3rem;
  }
}

@media only screen and (max-width: 890px) {
  .about-vector img {
    width: 324px;
  }

  .about-content h3 {
    font-size: 2.5rem;
  }

  .about-content p {
    font-size: 0.9rem;
    text-align: justify;
  }

  .content {
    width: 100%;
    padding: 0;
  }

  .footer-content {
    padding: 0 7%;
  }
}

@media only screen and (max-width: 768px) {
  .card__card__title {
    text-align: center;
  }

  .to-apply {
    padding: 2rem 2rem 3.5rem;
  }

  .to-apply h3 {
    font-size: 2.3rem;
  }

  .to-apply-card:hover {
    transform: unset;
  }

  .to-apply {
    padding: 2rem 2rem 3.5rem;
  }

  .timeline-head h3 {
    font-size: 2.5rem;
  }

  .overview-vector {
    display: none;
  }

  .overview-content {
    width: 100%;
    padding: 0 20px;
  }

  .sponsor-img {
    transform: translate(0, -50%) scale(0.7);
  }

  .img-div {
    margin-bottom: 2%;
  }

  .f-media a {
    margin-left: 15px;
  }

  .f-media i {
    font-size: 18px;
  }

  .f-bottom-left p {
    font-size: 16px;
    margin-right: 40px;
  }

  .f-bottom-right p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 680px) {
  .main-header-content {
    padding: 5rem 6rem 9rem;
  }

  .main-header-content h1 {
    display: block;
    font-size: 3rem;
  }

  .header-logo img {
    width: 150px;
  }

  .about-swoc {
    padding: 0rem 2rem 2rem;
  }

  .about-content h3 {
    font-size: 2rem;
  }

  .about-content p {
    font-size: 0.9rem;
    padding: 0 2.5rem;
  }

  .faqs {
    padding: 2rem 2rem 2rem;
  }

  .question {
    font-size: 0.9rem;
  }

  .plus {
    font-size: 1.5em;
  }

  .question-input:checked ~ .answers {
    height: 130px;
  }
}

@media only screen and (max-width: 535px) {
  .header {
    min-height: 350px;
  }

  .main-header-content {
    padding: 4rem 6rem 7rem;
  }

  .main-header-content h1 {
    display: block;
    font-size: 2rem;
  }

  .header-logo img {
    width: 120px;
  }

  .hamburger-icon {
    color: #fff;
    /* font-size: 1.2rem;
		 */
  }

  .about-swoc {
    padding: 0rem 2rem 1rem;
  }

  .about-vector img {
    width: 324px;
  }

  .about-content h3 {
    font-size: 1.5rem;
  }

  .about-content p {
    font-size: 0.8rem;
    padding: 0 2rem;
  }

  .card-text {
    font-size: 0.9rem;
  }

  .timeline-head h3 {
    font-size: 1.9rem;
  }

  .footer-mid .f-content .f-contact {
    width: 100%;
  }

  .footer-mid .f-content .f-logo {
    display: none;
  }

  .footer-copyright-info {
    padding-top: 30px;
  }

  .f-bottom-row {
    display: block;
    text-align: center;
  }

  .f-bottom {
    display: block;
  }

  .f-bottom-left p {
    margin-right: 0;
  }

  .f-media a {
    margin: 0 7px;
  }

  .footer-bottom p {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 420px) {
  .header {
    min-height: 320px;
  }

  .main-header-content {
    padding: 4rem 4rem 5rem;
  }

  .main-header-content h1 {
    display: block;
    font-size: 2rem;
  }

  .header-logo img {
    width: 110px;
  }

  .about-vector img {
    width: 324px;
  }

  .about-content h3 {
    font-size: 1.3rem;
  }

  .about-content p {
    font-size: 0.7rem;
    padding: 0;
  }

  .to-apply h3 {
    font-size: 1.9rem;
  }

  .card-text {
    font-size: 0.85rem;
  }
}

@media only screen and (max-width: 360px) {
  .main-header-content {
    padding: 4rem 2rem 5rem;
  }

  .main-header-content h1 {
    display: block;
    font-size: 2rem;
  }

  .header-logo img {
    width: 110px;
  }

  .about-swoc {
    padding: 0rem 1rem 1rem;
  }

  .about-vector img {
    width: 324px;
  }

  .about-content h3 {
    font-size: 1.2rem;
  }

  .about-content p {
    font-size: 0.65rem;
  }
}

.sliding-container {
  overflow: hidden;
  position: relative;
}

.sliding-track {
  display: flex;
  width: calc(200%); /* Double the width for seamless looping */
  animation: marquee-ltr 10s linear infinite;
}

.sliding-item {
  flex: 0 0 25%; /* Ensure each item takes up 25% of the container */
  box-sizing: border-box;
}

.sponsor-img {
  width: 100%;
  height: auto;
}

@keyframes marquee-ltr {
  0% {
    transform: translateX(-50%); /* Start from the left half */
  }
  100% {
    transform: translateX(0); /* End at the original position */
  }
}

.title{
    font-size: 2.5rem;
    font-weight: 300;
    text-align: center;
    margin-top: 80px;
}