html {
    scroll-behavior: smooth !important;
}

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

ul, li {
    margin: 0;
    padding: 0;
}

label {
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: #064a93;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #011830;
}

.apply-button {
    height: 44px;
    width: 312px;
}