.progress-bar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 6.6px;  /* height */
    background-color: rgba(255, 255, 255, 0.2); 
    z-index: 9999;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #03aeed; 
    transition: width 0.2s ease-out;
  }
